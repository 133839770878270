<template>
  <div class="page-order">
    <vanTitle title="我的订单"></vanTitle>
    <van-tabs v-model:active="state.active" color="#FC6C21" line-width="14px" title-active-color="#FC6C21" title-inactive-color="#999999" @click="bindTab">
      <van-tab :title="item.title" :name="item.name" v-for="(item ,index) in state.titleData" :key="index">
        <van-list v-model:loading="state.loading" loading-text="加载中..." :finished="state.finished" :immediate-check="false" finished-text="没有更多了" @load="onLoad" :offset="10">
          <van-cell v-for="(item ,index) in state.orderData" :key="index">
            <li class="item" @click="toDetail(item.id)">
              <div class="hd">
                <p class="number" @click.stop="copy(item.order_sn)">订单号: {{item.order_sn}} <i class="icon-copy"></i></p>
                <!-- 状态(0-未付款1-已付款2-已取消3-已退款4-部分退款5-退款中) -->
                <p class="status" :class="{'status-01': item.pay_status == '1', 'status-02': item.pay_status == '0'}">{{item.pay_status_val}}</p>
              </div>
              <p class="title f-owt-two">{{item.order_name}}</p>
              <p class="price"><span>¥</span>{{item.amount}}</p>
              <div class="bt">
                <!-- <div class="btn btn-01" @click.stop="getWuliu(item.id)" v-if="item.need_post == 1 && item.pay_status == 1">查看物流</div> -->
                <template v-if="item.order_type !== 2 && item.order_type !== 3 && item.order_type !== 4">
                  <div class="btn btn-01" @click.stop="bindStatus(item.order_sn,item.id)" v-if="item.refund_id">查看售后</div>
                  <div class="btn btn-01" @click.stop="bindSale(item.order_sn,item.id)" v-if="item.can_refund == '1' && !item.refund_id && item.amount !== '0.00' && item.platform != 8">申请售后</div>
                </template>
                <div class="btn btn-02" v-if="item.pay_status == '0'">去支付</div>
                <!-- 普通订单已支付展示去学习or拼团课程已支付and拼团成功展示去学习 -->
                <template v-if="item.order_type !== 2 && item.order_type !== 3 && item.order_type !== 4">
                  <div class="btn btn-03" v-if="item.flag == 0 && item.pay_status == '1' && item.order_type !== 5 || item.flag == 1 && item.pay_status == '1' && item.group_success">去学习</div>
                </template>
              </div>
            </li>
          </van-cell>
        </van-list>
        <vantEmpty :show="state.empty_show" text="暂无订单哦～" type="order"></vantEmpty>
      </van-tab>
    </van-tabs>
    <wuLiu :show="state.show" :wuliuData="state.wuliuData" :wuliu_empty_show="state.wuliu_empty_show" @bindPop="bindPop"></wuLiu>
  </div>
</template>

<script>
import { reactive, onMounted, watchEffect, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import useClipboard from 'vue-clipboard3'
import wuLiu from '../../components/wuLiu/index.vue'
import vantEmpty from '../../components/vantEmpty/index.vue'
export default {
  name: 'order',
  components: {
    wuLiu,
    vantEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const state = reactive({
      orderData: [], //课程详情
      wuliuData: [], //物流信息
      active: 0,
      pay_status: '100',
      page: 1,
      loading: false,
      finished: false,
      total: 0,
      show: false,
      empty_show: false,
      wuliu_empty_show: false,
      titleData: [
        {
          title: '全部',
          name: '100'
        },
        {
          title: '未支付',
          name: '0'
        },
        {
          title: '已支付',
          name: '1'
        },
        {
          title: '已退款',
          name: '3'
        },
        {
          title: '已取消',
          name: '2'
        }
      ]
    })

    onMounted(() => {
      getOrder() //获取数据
    })

    watchEffect(() => {
      // console.log("数据监听===>", props.courseList);
    })

    const toDetail = (id) => {
      $router.push({
        name: 'orderDetail',
        params: {
          id: id,
          source: 'list'
        }
      })
    }

    const toLogistics = (id) => {
      $router.push({
        name: 'orderLogistics',
        params: {
          id
        }
      })
    }

    // 切换类型
    const bindTab = (name, title) => {
      state.pay_status = name
      state.finished = false
      state.orderData = []
      state.page = 1
      getOrder()
    }

    //滚动加载时触发，list组件定义的方法
    const onLoad = () => {
      state.page++
      getOrder()
      console.log('加载更多～')
    }

    // 复制订单号
    const { toClipboard } = useClipboard()
    const copy = async (Msg) => {
      try {
        //复制
        await toClipboard(Msg)
        proxy.$toast('复制成功')
      } catch (e) {
        //复制失败
        proxy.$toast('复制失败')
      }
    }

    // 获取订单列表
    const getOrder = () => {
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      proxy.$http
        .get('/api/order/list', {
          params: {
            pay_status: state.pay_status,
            page: state.page
          }
        })
        .then((res) => {
          proxy.$toast.clear()
          const body = res.data.data

          if (res.data.code == '200') {
            state.loading = false
            state.total = body.total

            if (body.total == 0) {
              state.empty_show = true
            } else {
              state.empty_show = false
            }

            if (body.data == null || body.data.length === 0) {
              state.finished = true
              return
            }
            state.orderData = state.orderData.concat(body.data)

            if (state.page >= body.last_page) {
              state.finished = true
            }
          } else if (res.data.code == '401') {
            console.log('未登录')
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 获取物流
    const getWuliu = (id) => {
      // 找出对应的订单
      let orderItem = state.orderData.find((item) => item.id == id)
      if (!orderItem) {
        return
      }

      if (orderItem.multi_express == 1) {
        return toLogistics(id) // 多物流，跳转至订单
      }

      proxy.$http
        .get('/api/transport/detail', {
          params: {
            order_id: id
          }
        })
        .then((res) => {
          console.log('物流===>', res.data)
          const body = res.data

          if (res.data.code == '200') {
            if (body.data == '') {
              state.wuliu_empty_show = true
            } else {
              state.wuliu_empty_show = false
            }

            for (let i in body.data) {
              body.data[i].date = body.data[i].ftime.slice(0, 5)
              body.data[i].time = body.data[i].ftime.slice(6, 11)
            }
            state.wuliuData = body
            state.show = true
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }

    const bindPop = () => {
      state.show = false
    }

    //申请售后
    const bindSale = (order_sn, id) => {
      $router.push({
        path: '/order/refund',
        query: {
          order_sn: order_sn,
          id: id
        }
      })
    }

    //查看售后
    const bindStatus = (order_sn, id) => {
      $router.push({
        path: '/order/refundStatus',
        query: {
          order_sn: order_sn,
          id: id
        }
      })
    }

    return {
      state,
      toDetail,
      getOrder,
      bindTab,
      onLoad,
      copy,
      bindPop,
      // getWuliu,
      bindSale,
      bindStatus
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss">
.page-order {
  .item {
    padding: 0 15px;
    background: #ffffff;
    border-radius: 8px;
    margin: 12px 15px 0 15px;
    .hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      .number {
        font-size: 12px;
        color: #999999;
        line-height: 17px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: none;
        padding: 12px 0;
        .icon-copy {
          display: block;
          width: 12px;
          height: 12px;
          margin-left: 5px;
          background: url(../../assets/icons/icon-copy.png) center/100% 100%
            no-repeat;
        }
      }
      .status {
        font-size: 13px;
        color: #9fa6af;
        line-height: 16px;
        white-space: nowrap;
      }
      .status-01 {
        color: #fc6c21;
      }
      .status-02 {
        color: #ff2223;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin: 12px 0;
      height: 40px;
      text-align: start;
    }
    .price {
      font-size: 14px;
      font-weight: 500;
      color: #fc6c21;
      line-height: 20px;
    }
    .bt {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px;
      .btn {
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 12px;
        height: 24px;
        text-align: center;
        margin-left: 8px;
      }
      .btn-01 {
        border: 1px solid #fc6c21;
        color: #fc6c21;
      }
      .btn-02 {
        background: linear-gradient(270deg, #f30406 0%, #ff5b07 100%);
        color: #ffffff;
      }
      .btn-03 {
        background: linear-gradient(137deg, #ff9d5b 0%, #fc6c21 100%);
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        color: #ffffff;
      }
    }
  }
  /deep/ .van-cell {
    padding: 0 !important;
    background: none !important;
  }
}
</style>
