<template>
  <div id="login">
    <vanTitle title="登录"></vanTitle>
    <div class="login-page">
      <img src="../../assets/image/login/logo.png" class="logo">
      <div class="item">
        <input type="text" placeholder="请输入手机号" class="phone" v-model="state.mobile" maxlength="11" />
      </div>
      <div class="item">
        <input type="text" placeholder="请输入验证码" class="code" v-model="state.code" maxlength="4" />
        <div class="btn" @click="bindCode" v-if="state.disabled">获取验证码</div>
        <div class="btn btn-01" v-if="!state.disabled">{{state.auth_time}}s</div>
      </div>
      <div class="protocol">
        <div class="checkbox" @click="onCheck" :style="{background:'url(' + state.protocol_check[state.protocol_check_id] + ') no-repeat center/100%'}"></div>
        <div class="container">
          <span class="color_gray" @click="onCheck">登录/注册代表您已同意</span>
          <span @click="toProtocol(0)">《用户注册协议》</span>
          及
          <span @click="toProtocol(1)">《用户隐私协议》</span>
        </div>
      </div>
      <div class="big-btn" @click="bindLogin">登录</div>
    </div>
    <van-popup :show="state.show" round @close="onClose" @opened="onOpened" @click-overlay="onClose">
      <div class="pop-box">
        <div class="title">验证</div>
        <div id="__nc" style="width:100%;">
          <div id="nc"></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getStore } from '../../utils/util'
export default {
  name: 'login',
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const $route = useRoute()
    const store = useStore()
    const state = reactive({
      mobile: '', //手机号
      code: '', //验证码
      show: false, //滑动验证弹窗
      btntxt: '获取验证码',
      disabled: true,
      auth_time: 60,
      back_url: '',
      protocol_check_id: 0,
      protocol_check: [
        'https://file.huatu.com/static/miniprogram/shop/h5/login/icon-protocol-check-unactive.png',
        'https://file.huatu.com/static/miniprogram/shop/h5/login/icon-protocol-check-active@2x.png'
      ]
    })

    onMounted(() => {
      if ($route.query.redirect) {
        state.back_url = $route.query.redirect
      }
      console.log($route.query.redirect)
      handleInputEventFunc()
    })

    const handleInputEventFunc = () => {
      // 注意看是否有input type=button等其他input的，这里没有，所以可以这么写
      let inputElements = document.getElementsByTagName('input')
      inputElements = Array.from(inputElements)
      inputElements.forEach((ele) => {
        ele.onfocus = () => {
          window.inputFocuseTimer && clearTimeout(window.inputFocuseTimer)
        }
        ele.onblur = () => {
          window.inputFocuseTimer && clearTimeout(window.inputFocuseTimer)
          window.inputFocuseTimer = setTimeout(() => {
            window.document.body.scrollTop = window.document.body.scrollHeight
          }, 0)
        }
      })
    }

    // 初始化滑动验证
    const onOpened = () => {
      init()
    }

    // 关闭弹窗
    const onClose = () => {
      state.show = false
    }

    const onCheck = () => {
      state.protocol_check_id = Math.abs(1 - state.protocol_check_id)
    }

    const toProtocol = (protocolId) => {
      const protocols = ['user_registration_protocol', 'user_privacy_protocol']
      // $router.push({
      //     name: 'protocol',
      //     query: {
      //         id: protocolId
      //     }
      // });
      // return false;
      window.location.href =
        'https://file.huatu.com/static/agreement/web/index.html?name=' +
        protocols[protocolId]
    }

    // 获取验证码按钮
    const bindCode = () => {
      let reg = /^1[0-9]{10}$/
      if (state.mobile == '') {
        proxy.$toast('请输入手机号～')
        return false
      }
      if (!reg.test(state.mobile)) {
        proxy.$toast('手机号格式错误～')
        return false
      }

      state.show = true
    }

    // 登录
    const bindLogin = () => {
      let reg = /^1[0-9]{10}$/
      state.mobile = state.mobile.replace(/\s+/g, '')

      if (state.mobile == '') {
        proxy.$toast('请输入手机号～')
        return false
      }
      if (!reg.test(state.mobile)) {
        proxy.$toast('手机号格式错误～')
        return false
      }
      if (state.code == '') {
        proxy.$toast('请输入验证码～')
        return false
      }
      if (state.protocol_check_id == 0) {
        proxy.$toast('请同意软件使用协议')
        return false
      }
      let data = {
        mobile: state.mobile,
        code: state.code
      }
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })
      proxy.$http
        .post('/api/auth/login', data)
        .then((res) => {
          console.log(res)
          proxy.$toast.clear()
          const body = res.data.data

          if (res.data.code == '200') {
            const access_token = 'Bearer ' + body.access_token
            const userInfo = body

            store.commit('userInfo/setAccessToken', access_token) //存入缓存
            store.commit('userInfo/setUser', userInfo) //存入缓存
            let unionId = userInfo.unionId ? userInfo.unionId : '-1'
            localStorage.setItem('htjy_unionId', unionId)
            console.log(window.history)
            try {
              sa.login(state.mobile) // 成功后上报手机号
              //未登录去登录成功后上报huatu_id、union_id、huatu_code
              const getuserInfo = getStore('userInfo')
              console.log('getuserInfo-登录成功', getuserInfo)
              sa.setOnceProfile({
                // huatu_id: getuserInfo.huatuId,
                huatu_code: getuserInfo.huatuCode,
                huatu_edu_union_id: getuserInfo.unionId
              })
            } catch (error) {
              console.log('error: ', error)
            }
            setTimeout(() => {
              if (state.back_url == '/order') {
                console.log('ready to go:', state.back_url)
                $router.push('/order')
              } else {
                $router.go(-1) //返回原页面
              }
            }, 200)
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    //获取验证码
    const getCode = (data) => {
      proxy.$http
        .post('/api/auth/sendMsg', data)
        .then((res) => {
          if (res.data.code == 200) {
            state.show = false
            state.disabled = false
            state.auth_time = 60

            var auth_timetimer = setInterval(() => {
              state.auth_time--
              if (state.auth_time <= 0) {
                state.disabled = true
                clearInterval(auth_timetimer)
              }
            }, 1000)

            proxy.$toast('验证码获取成功')
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 滑动验证
    const init = () => {
      var nc_token = [
        'FFFF0N000000000099DF',
        new Date().getTime(),
        Math.random()
      ].join(':')
      var nc = NoCaptcha.init({
        renderTo: '#nc',
        appkey: 'FFFF0N000000000099DF',
        scene: 'nc_register_h5',
        token: nc_token,
        trans: { key1: 'code200' },
        elementID: ['usernameID'],
        is_Opt: 0,
        language: 'cn',
        timeout: 10000,
        retryTimes: 5,
        errorTimes: 5,
        inline: false,
        apimap: {
          // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
          // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
        },
        bannerHidden: false,
        initHidden: false,
        callback: (data) => {
          // 滑动后回调
          let formData = {
            session_id: data.csessionid,
            token: nc_token,
            sig: data.sig,
            scene: 'nc_register_h5',
            mobile: state.mobile
          }

          let reg = /^1[0-9]{10}$/
          if (state.mobile == '') {
            proxy.$toast('请输入手机号～')
            init()
            return false
          }
          if (!reg.test(state.mobile)) {
            proxy.$toast('手机号格式错误～')
            init()
            return false
          }

          getCode(formData)
        },
        error: function (error) {
          console.log(error)
        }
      })
      NoCaptcha.setEnabled(true)
      nc.reset() //请务必确保这里调用一次reset()方法

      NoCaptcha.upLang('cn', {
        LOADING: '加载中...', //加载
        SLIDER_LABEL: '请向右滑动获取验证～', //等待滑动
        CHECK_Y: '验证通过', //通过
        ERROR_TITLE: '非常抱歉，这出错了...', //拦截
        CHECK_N: '验证未通过', //准备唤醒二次验证
        OVERLAY_INFORM: '经检测你当前操作环境存在风险，请输入验证码', //二次验证
        TIPS_TITLE: '验证码错误，请重新输入' //验证码输错时的提示
      })
    }

    return {
      state,
      bindLogin,
      getCode,
      bindCode,
      onOpened,
      onClose,
      onCheck,
      toProtocol
    }
  }
}
</script>

<style scoped lang="scss">
.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url(../../assets/image/login/bg.png) center/100% 100% no-repeat;
  .logo {
    width: 70px;
    height: 70px;
    margin: 42px auto;
  }
  .item {
    width: 90%;
    background: #fff8f2;
    border-radius: 4px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #333333;
    .phone {
      width: 100%;
      height: 50px;
      background: #fff8f2;
      border-radius: 4px;
      padding: 0 12px;
    }
    .code {
      flex: 1;
      height: 50px;
      background: #fff8f2;
      padding-left: 12px;
    }
    .btn {
      height: 50px;
      color: #fc6c21;
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
    .btn-01 {
      color: #999999;
    }
  }
  .big-btn {
    margin-top: 45px;
  }
  .protocol {
    display: flex;
    align-items: flex-start;
    margin: 0 15px;
    .checkbox {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      margin-top: 2px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .container {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      span {
        color: #fc6c21;
      }
    }
  }
}
.pop-box {
  width: 264px;
  height: 130px;
  .title {
    margin: 15px 0 25px 0;
  }
}
/deep/._nc .stage1 .bg-green {
  background: linear-gradient(90deg, #ff9d5b 0%, #fc6c21 100%);
}
/deep/._nc .icon-ok {
  color: #fc7930;
}
/deep/._nc .stage1 .slider {
  box-shadow: none;
}
.color_gray {
  color: #999 !important;
}
</style>